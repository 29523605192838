import React, { useState, useEffect, useRef} from 'react';
import Header from './Header';
import axios from 'axios';
import moment from 'moment-timezone';
import BitacoraRegistros from './BitacorasRegistros';
import config from './config'; // Asegúrate de ajustar la ruta según la ubicación de tu archivo config

const Bitacora = () => {
  const [bitacoras, setBitacora] = useState([]);
  const [funcionarios, setFuncionarios] = useState([]);
  const [Personal, setPersonal] = useState([]);
  const [Docentes, setDocentes] = useState([]);
 
  const [base64Image, setBase64Image] = useState(''); // Estado para almacenar la imagen en base64
  const fileInputRef = useRef(null); // Ref para el input de archivo


  const [formData, setFormData] = useState({
    cedula: "",
    nombre: "",
    cedulapersona: "",
    nombrePersona: "",
    estado: "",
    observacion: "",
    fecha: "",
    fotoUrl: "",
  });

  const [mensaje, setMensaje] = useState('');

 
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Convertir la imagen a base64
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result.split(',')[1]; // Eliminar el prefijo
      setBase64Image(base64String);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const { cedula, nombre, cedulapersona, nombrePersona, estado, observacion  } = formData;

    try {
      const response = await axios.post(`${config.baseURL}/api/v1/bitacora`, {
        cedula: cedula,
        nombre: nombre,
        cedulapersona: cedulapersona,
        nombrePersona: nombrePersona,
        estado: estado,
        observacion: observacion,
        fecha: moment().tz("America/Bogota").format(),
        fotoUrl: base64Image, // Se envía la imagen en Base64
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
  
      console.log("Registro de Bitácora Creado:", response.data);
  
      // Resetear el formulario solo si el envío fue exitoso
      setFormData({
        cedula: "",
        nombre: "",
        cedulapersona: "",
        nombrePersona: "",
        estado: "",
        observacion: "",
        fecha: "",
        fotoUrl: "",
      });
  
   
      setMensaje("Formulario enviado con éxito.");

      // Reiniciar el handler de la imagen
      setBase64Image('');


   // 🔥 Resetear el input file manualmente 🔥
   if (fileInputRef.current) {
    fileInputRef.current.value = ""; // Borra el valor del input de archivo
  }

      // Llamar a fetchBitacora solo si la petición fue exitosa
      fetchBitacora();
    } catch (error) {
      console.error("Error al crear Bitácora:", error);
      setMensaje("Hubo un error al enviar la bitácora.");
    }
  };
  

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const fetchBitacora = async () => {
    try {
      const response = await axios.get(`${config.baseURL}/api/v1/bitacora`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      setBitacora(response.data);
    } catch (error) {
      console.error('Error obteniendo Bitacora:', error);
    }
  };

  const fetchFuncionarios = async () => {
    try {
      const response = await axios.get(`${config.baseURL}/api/v1/funcionariostecnologia`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      setFuncionarios(response.data);
    } catch (error) {
      console.error('Error obteniendo Funcionarios:', error);
    }
  };

  const fetchPersonal = async () => {
    try {
      const response = await axios.get(`${config.baseURL}/api/v1/personal`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      setPersonal(response.data.data);
    } catch (error) {
      console.error('Error obteniendo Personal:', error);
    }
  };

  const fetchDocentes = async () => {
    try {
      const response = await axios.get(`${config.baseURL}/api/v1/docente`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      setDocentes(response.data.data);
    } catch (error) {
      console.error('Error obteniendo Docentes:', error);
    }
  };


  useEffect(() => {
    fetchBitacora();
    fetchFuncionarios();
    fetchPersonal();
    fetchDocentes();
  }, []);

  useEffect(() => {
    if (formData.cedula) {
      const funcionarioEncontrado = funcionarios.find(funcionario => funcionario.cedula === formData.cedula);
      if (funcionarioEncontrado) {
        setFormData(prevFormData => ({ ...prevFormData, nombre: funcionarioEncontrado.nombre }));
      } else {
        setFormData(prevFormData => ({ ...prevFormData, nombre: "" }));
      }
    } else {
      setFormData(prevFormData => ({ ...prevFormData, nombre: "" }));
    }

    if (formData.cedulapersona) {
      const personalEncontrado = Personal.find(personal => personal.cedula === formData.cedulapersona);
      const docenteEncontrado = Docentes.find(docente => docente.cedula === formData.cedulapersona);
    
      if (personalEncontrado) {
        setFormData(prevFormData => ({ ...prevFormData, nombrePersona: personalEncontrado.nombre }));
      } else if (docenteEncontrado) {
        setFormData(prevFormData => ({ ...prevFormData, nombrePersona: docenteEncontrado.nombre }));
      } else {
        setFormData(prevFormData => ({ ...prevFormData, nombrePersona: "" }));
      }
    } else {
      setFormData(prevFormData => ({ ...prevFormData, nombrePersona: "" }));
    }


  }, [formData.cedula, funcionarios, formData.cedulapersona, Personal, Docentes]);



  return (
  <div className="flex flex-col min-h-screen bg-gradient-to-b from-[#0A6A50] to-[#ADE498]">
  <Header />
  <div className="flex flex-col flex-grow mx-2 my-4 sm:my-auto">
    <div
      className="mt-4 mb-4 flex flex-col flex-grow w-full max-w-[99%] sm:w-1/2 mx-auto bg-white rounded-lg shadow-md p-6 sm:p-8 overflow-hidden"
   
    >
      <h1 className="text-[#0A6A50] mb-4 text-2xl sm:text-3xl font-semibold text-center">
        Bitácora
      </h1>

      <form onSubmit={handleSubmit} className="mt-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div className="flex flex-col">
            <label htmlFor="cedula" className="font-semibold text-gray-600">
              Cédula Contratista:
            </label>
            <input
              type="text"
              id="cedula"
              name="cedula"
              placeholder="Cédula"
              className="py-2 px-3 mt-2 text-gray-800 border-2 border-gray-100 focus:outline-none focus:border-gray-200 rounded-md w-full"
              value={formData.cedula}
              onChange={handleChange}
              required
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="nombre" className="text-sm font-semibold text-gray-600">
              Nombre:
            </label>
            <input
              type="text"
              id="nombre"
              name="nombre"
              placeholder="Nombre"
              className="py-2 px-3 mt-2 text-gray-800 border-2 border-gray-100 focus:outline-none focus:border-gray-200 rounded-md w-full"
              value={formData.nombre}
              onChange={handleChange}
              readOnly
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="cedulapersona" className="font-semibold text-gray-600">
              Cédula Persona:
            </label>
            <input
              type="text"
              id="cedulapersona"
              name="cedulapersona"
              placeholder="Cédula de la Persona"
              className="py-2 px-3 mt-2 text-gray-800 border-2 border-gray-100 focus:outline-none focus:border-gray-200 rounded-md w-full"
              value={formData.cedulapersona}
              onChange={handleChange}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="nombrePersona" className="text-sm font-semibold text-gray-600">
              Nombre Persona:
            </label>
            <input
              type="text"
              id="nombrePersona"
              name="nombrePersona"
              placeholder="Nombre de la Persona"
              className="py-2 px-3 mt-2 text-gray-800 border-2 border-gray-100 focus:outline-none focus:border-gray-200 rounded-md w-full"
              value={formData.nombrePersona}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="flex flex-col sm:flex-row sm:items-center mt-4">
          <label className="font-semibold text-gray-600 mb-2 sm:mb-0 sm:mr-4">Novedad:</label>
          <div className="flex flex-wrap gap-3">
            {["SALE", "ENTRA", "TRASLADO", "INFORME"].map((estado) => (
              <label key={estado} className="flex items-center">
                <input
                  type="radio"
                  name="estado"
                  value={estado}
                  checked={formData.estado === estado}
                  onChange={handleChange}
                  className="mr-2"
                />
                {estado}
              </label>
            ))}
          </div>
        </div>

        <div className="flex flex-col mt-4 text-sm">
          <label htmlFor="observacion" className="text-sm font-semibold text-gray-600">
            Observaciones:
          </label>
          <textarea
            id="observacion"
            name="observacion"
            placeholder="Observaciones"
            className="py-2 px-3 mt-2 text-gray-800 border-2 border-gray-100 focus:outline-none focus:border-gray-200 rounded-md w-full"
            value={formData.observacion}
            onChange={handleChange}
            rows={4}
          />
        </div>

        <div className="flex flex-col mt-4">
          <label htmlFor="file"  className="font-semibold text-gray-600">
            Seleccionar Archivo:
          </label>
          <input
            type="file"
            ref={fileInputRef}
            id="file"
            placeholder='Selecciona solo imágenes en formato .jpg, .jpeg, .png'
            name="file"
            className="mt-2"
            onChange={handleFileChange}
          />
        </div>

        <div className="flex justify-center mt-6">
          <button
            type="submit"
            className="py-2 px-4 text-white bg-[#0A6A50] rounded-md hover:bg-[#0A6A70] w-full sm:w-auto"
          >
            Enviar
          </button>
        </div>
      </form>

      {mensaje && <p className="mt-4 text-center text-sm text-gray-600">{mensaje}</p>}
    </div>

    <BitacoraRegistros bitacoras={bitacoras} />
  </div>
</div>

  );
}

export default Bitacora;
