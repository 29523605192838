import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "./config";
import Header from "./Header";

const Registros = () => {
  const [docentes, setDocentes] = useState([]);
  const [personal, setPersonal] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editando, setEditando] = useState(null);
  const [formData, setFormData] = useState({});
  const [busqueda, setBusqueda] = useState("");

  useEffect(() => {
    obtenerDocentes();
    obtenerPersonal();
  }, []);

  const obtenerDocentes = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No hay un token de autenticación");
      setLoading(false);
      return;
    }
    try {
      const response = await axios.get(`${config.baseURL}/api/v1/docente`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setDocentes(response.data.data);
    } catch (error) {
      setError("Error obteniendo docentes");
    }
    setLoading(false);
  };

  const obtenerPersonal = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No hay un token de autenticación");
      setLoading(false);
      return;
    }
    try {
      const response = await axios.get(`${config.baseURL}/api/v1/personal`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setPersonal(response.data.data);
    } catch (error) {
      setError("Error obteniendo personal");
    }
    setLoading(false);
  };

  const handleEditar = (registro, tipo) => {
    setEditando({ ...registro, tipo });
    setFormData({ ...registro });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleGuardar = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No hay un token de autenticación");
      return;
    }

    const url =
      editando.tipo === "docente"
        ? `${config.baseURL}/api/v1/docente/${editando.id}`
        : `${config.baseURL}/api/v1/personal/${editando.id}`;

    try {
      await axios.put(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      editando.tipo === "docente" ? obtenerDocentes() : obtenerPersonal();
      setEditando(null);
    } catch (error) {
      setError("Error actualizando registro");
    }
  };

  const filtrarResultados = (items) =>
    items.filter(
      (item) =>
        item.nombre.toLowerCase().includes(busqueda.toLowerCase()) ||
        item.cedula.toLowerCase().includes(busqueda.toLowerCase()) ||
        (item.correo && item.correo.toLowerCase().includes(busqueda.toLowerCase()))
    );

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-[#0A6A50] to-[#ADE498]">
  <Header />
      <div className=" mt-4 container mx-auto bg-white p-6 rounded-lg shadow-md">
        <h2 className="text-3xl font-semibold text-[#0A6A50] text-center">Gestión de Docentes y Personal</h2>

        {error && <p className="text-red-600 text-center mt-2">{error}</p>}
        {loading && <p className="text-gray-600 text-center mt-2">Cargando...</p>}

        {/* Formulario de Edición en la Parte Superior */}
        {editando && (
          <div className="bg-gray-100 p-6 rounded-lg shadow-md mt-4">
            <h3 className="text-xl font-semibold text-[#0A6A50] mb-4">Editando {editando.tipo === "docente" ? "Docente" : "Personal"}</h3>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <input className="border p-2 rounded-md" type="text" name="nombre" value={formData.nombre || ""} onChange={handleChange} placeholder="Nombre" />
              <input className="border p-2 rounded-md" type="text" name="cedula" value={formData.cedula || ""} onChange={handleChange} placeholder="Cédula" />
              
              {editando.tipo === "docente" ? (
                <>
                  <input className="border p-2 rounded-md" type="text" name="facultad" value={formData.facultad || ""} onChange={handleChange} placeholder="Facultad" />
                  <input className="border p-2 rounded-md" type="text" name="tipocontrato" value={formData.tipocontrato || ""} onChange={handleChange} placeholder="Tipo de Contrato" />
                </>
              ) : (
                <>
                  <input className="border p-2 rounded-md" type="text" name="dependencia" value={formData.dependencia || ""} onChange={handleChange} placeholder="Dependencia" />
                  <input className="border p-2 rounded-md" type="text" name="cargo" value={formData.cargo || ""} onChange={handleChange} placeholder="Cargo" />
                </>
              )}

              <input className="border p-2 rounded-md" type="email" name="correo" value={formData.correo || ""} onChange={handleChange} placeholder="Correo" />
              <input className="border p-2 rounded-md" type="text" name="telefono" value={formData.telefono || ""} onChange={handleChange} placeholder="Teléfono" />
            </div>

            <div className="flex justify-end mt-4 gap-2">
              <button className="px-4 py-2 bg-[#0A6A50] text-white rounded-md hover:bg-[#085C42]" onClick={handleGuardar}>Guardar</button>
              <button className="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-500" onClick={() => setEditando(null)}>Cancelar</button>
            </div>
          </div>
        )}

        {/* Barra de Búsqueda */}
        <input
          type="text"
          placeholder="Buscar por nombre, cédula o correo..."
          value={busqueda}
          onChange={(e) => setBusqueda(e.target.value)}
          className="w-full p-2 border rounded-md my-4"
        />

        {/* Tabla de Docentes */}
        <h3 className="text-xl font-semibold text-[#0A6A50]">Docentes</h3>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse border">
            <thead className="bg-gray-200">
              <tr>
                <th className="border p-2">Cédula</th>
                <th className="border p-2">Nombre</th>
                <th className="border p-2">Facultad</th>
                <th className="border p-2">Correo</th>
                <th className="border p-2">Tipo de Contrato</th>
                <th className="border p-2">Teléfono</th>
                <th className="border p-2">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {filtrarResultados(docentes).map((docente) => (
                <tr key={docente.id} className="border">
                  <td className="p-2">{docente.cedula}</td>
                  <td className="p-2">{docente.nombre}</td>
                  <td className="p-2">{docente.facultad}</td>
                  <td className="p-2">{docente.correo}</td>
                  <td className="p-2">{docente.tipocontrato}</td>
                  <td className="p-2">{docente.telefono}</td>
                  <td className="p-2">
                    <button className="px-3 py-1 bg-green-800 text-white rounded-md hover:bg-green-900" onClick={() => handleEditar(docente, "docente")}>Editar</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Tabla de Personal */}
        <h3 className="text-xl font-semibold text-[#0A6A50] mt-4">Personal</h3>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse border">
            <thead className="bg-gray-200">
              <tr>
                <th className="border p-2">Cédula</th>
                <th className="border p-2">Nombre</th>
                <th className="border p-2">Dependencia</th>
                <th className="border p-2">Correo</th>
                <th className="border p-2">Teléfono</th>
                <th className="border p-2">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {filtrarResultados(personal).map((persona) => (
                <tr key={persona.id} className="border">
                  <td className="p-2">{persona.cedula}</td>
                  <td className="p-2">{persona.nombre}</td>
                  <td className="p-2">{persona.dependencia}</td>
                  <td className="p-2">{persona.correo}</td>
                  <td className="p-2">{persona.telefono}</td>
                  <td className="p-2">
                    <button className="px-3 py-1 bg-green-800 text-white rounded-md hover:bg-green-900" onClick={() => handleEditar(persona, "personal")}>Editar</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>

      </div>
    </div>
  );
};

export default Registros;
