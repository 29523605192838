import React from "react";
import axios from "axios";
import config from './config'; // Asegúrate de ajustar la ruta según la ubicación de tu archivo config
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { useEffect, useState } from "react";

const PrestamoInfo = ({ formData, onPrestamoReturned }) => {
  const token = localStorage.getItem("token");
  if (!token) throw new Error("Token not found");


  const { id, horainicio, horafin, Docente, Personal, Aula, observaciones, ArticuloPrestamo, llave, fecha, Cuenta } = formData;
  const handleDevolverTodo = async () => {
    try {
      // Actualizar disponibilidad de artículos
      const updateArticles = ArticuloPrestamo.map(async (articuloPrestamo) => {
        await axios.put(`${config.baseURL}/api/v1/articulo/${articuloPrestamo.articuloId}`, {
          disponible: true,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
      });

      // Verificar si `Aula` tiene un `id` válido antes de proceder
      if (Aula && Aula.id) {
        await axios.put(`${config.baseURL}/api/v1/aula/${Aula.id}`, {
          disponible: true,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
      } else {
        throw new Error("El objeto Aula no tiene un id válido.");
      }

      await Promise.all(updateArticles);

      // Eliminar registros de préstamos
      await axios.delete(`${config.baseURL}/api/v1/articuloprestamocompleto/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      await axios.delete(`${config.baseURL}/api/v1/prestamo/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      onPrestamoReturned(id);
    } catch (error) {
      console.error("Error al devolver el préstamo:", error);
    }
  };

  const handleDeleteArticulo = async (prestamoId, articuloId) => {
    try {
      await axios.put(`${config.baseURL}/api/v1/articulo/${articuloId}`, {
        disponible: true,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      await axios.delete(`${config.baseURL}/api/v1/articuloprestamo/${prestamoId}/${articuloId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      const updatedArticuloPrestamo = ArticuloPrestamo.filter(
        (articuloPrestamo) => articuloPrestamo.articuloId !== articuloId
      );

      const updatedPrestamo = { ...formData, ArticuloPrestamo: updatedArticuloPrestamo };

      onPrestamoReturned(updatedPrestamo);
    } catch (error) {
      console.error("Error al eliminar el artículo del préstamo:", error);
    }
  };

  const handleDeleteLlave = async () => {
    try {
      await axios.put(`${config.baseURL}/api/v1/prestamo/${id}`, {
        llave: "",
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      onPrestamoReturned({ ...formData, llave: "" });
    } catch (error) {
      console.error("Error al eliminar la llave:", error);
    }
  };

  const usuarioNombre = Docente ? Docente.nombre : (Personal ? Personal.nombre : "No especificado");
  const usuarioCedula = Docente ? Docente.cedula : (Personal ? Personal.cedula : "No especificado");
  const usuariodependencia = Docente ? Docente.facultad : (Personal ? Personal.dependencia : "No especificado")

// Función para formatear la fecha
const formatDate = (dateString) => {
  if (!dateString) return { formattedDate: "Fecha no especificada", isPreviousDay: false };
  const date = new Date(dateString);
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Establecer la hora a medianoche para comparar solo la fecha
  const isPreviousDay = date < today;
  const formattedDate = format(date, "EEEE d 'de' MMMM 'del' yyyy HH:mm", { locale: es });
  return { formattedDate, isPreviousDay };
};

  const handleDeleteObservaciones = async () => {
    try {
      await axios.put(`${config.baseURL}/api/v1/prestamoobservaciones/${id}`, {
        observaciones: "",
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      onPrestamoReturned({ ...formData, observaciones: "" });
    } catch (error) {
      console.error("Error al eliminar las observaciones:", error);
    }
  };

  const [articulosDisponibles, setArticulosDisponibles] = useState([]);
  const [showAvailableArticles, setShowAvailableArticles] = useState(false);

  useEffect(() => {
    fetchArticulosDisponibles();
  }, []);

  const fetchArticulosDisponibles = async () => {
    try {
      const response = await axios.get(`${config.baseURL}/api/v1/articulo`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const disponibles = response.data.data.filter(articulo => articulo.disponible === true);
      setArticulosDisponibles(disponibles);
    } catch (error) {
      console.error("Error fetching articulos disponibles:", error);
    }
  };

  const handleAddArticulo = async (articuloId) => {
    try {
      await axios.post(`${config.baseURL}/api/v1/articuloprestamo`, {
        prestamoId: id,
        articuloId: articuloId,
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Actualizar la disponibilidad del artículo
      await axios.put(`${config.baseURL}/api/v1/articulo/${articuloId}`, {
        disponible: false,
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Actualizar la lista de artículos prestados
      const articulo = articulosDisponibles.find(a => a.id === articuloId);
      const updatedPrestamo = {
        ...formData,
        ArticuloPrestamo: [...ArticuloPrestamo, { Articulo: articulo, articuloId }]
      };

      onPrestamoReturned(updatedPrestamo);
      setShowAvailableArticles(false);
      fetchArticulosDisponibles();
    } catch (error) {
      console.error("Error adding articulo:", error);
    }
  };

  const handleToggleArticles = () => {
    setShowAvailableArticles(!showAvailableArticles);
  };
 
  const fechaInfo = formatDate(fecha);

  return (
    <div className="p-4 sm:p-4 bg-white rounded-lg shadow-md lg:shadow-lg mt-2 mx-2">
      <h2 className="text-base text-[#0A6A50] font-bold">Préstamo generado:</h2>
      <div className="grid grid-cols-1 justify-between mt-2 text-sm">
        {Aula && Aula.nombreAula.toLowerCase() !== "sin aula" && (
          <p>
            <span className="font-semibold">Salón:</span> {Aula.nombreAula}
          </p>
        )}
        <p>
          <span className="font-semibold">Usuario:</span> {usuarioNombre}
        </p>
        <p>
          <span className="font-semibold">Cédula:</span> {usuarioCedula}
        </p>
        <p>
          <span className="font-semibold">Dependencia:</span> {usuariodependencia}
        </p>
        {Cuenta && Cuenta.nombre && (
          <p>
            <span className="font-semibold">Prestó:</span> {Cuenta.nombre}
          </p>
        )}
        <p>
          <span className="font-semibold">Fecha:</span>
          <span className={fechaInfo.isPreviousDay ? "text-red-600" : ""}>
            {fechaInfo.formattedDate}
          </span>
        </p>
        {horainicio && (
          <p>
            <span className="font-semibold">Inicio:</span> {horainicio}
          </p>
        )}
        {horafin && (
          <p>
            <span className="font-semibold">Fin:</span> {horafin}
          </p>
        )}
      </div>
      {observaciones && (
        <div className="flex justify-between items-center mt-2">
          <p className="text-sm">{observaciones}</p>
          <button
            type="button"
            className="ml-2 text-red-600 hover:text-red-800"
            onClick={handleDeleteObservaciones}
          >
            🗑️
          </button>
        </div>
      )}
      {(llave || (ArticuloPrestamo && ArticuloPrestamo.length > 0)) && (
        <div className="mt-4">
          <div className="flex items-center">
            <h3 className="text-sm font-semibold">Elementos prestados:</h3>
            <button
              type="button"
              className="ml-2 text-green-600 hover:text-green-800"
              onClick={handleToggleArticles}
            >
              ➕
            </button>
          </div>
          <ul className="list-disc list-inside mt-2 text-sm">
            {llave && (
              <li className="flex justify-between items-center">
                <p>{llave}</p>
                <button
                  type="button"
                  className="ml-2 text-red-600 hover:text-red-800"
                  onClick={handleDeleteLlave}
                >
                  🗑️
                </button>
              </li>
            )}
            {ArticuloPrestamo && ArticuloPrestamo.length > 0 && (
              ArticuloPrestamo.map((articuloPrestamo, index) => (
                <li key={index} className="flex justify-between items-center">
                  {articuloPrestamo.Articulo.nombreArticulo}
                  <div>
                    <button
                      type="button"
                      className="ml-2 text-red-600 hover:text-red-800"
                      onClick={() => handleDeleteArticulo(id, articuloPrestamo.articuloId)}
                    >
                      🗑️
                    </button>
                  </div>
                </li>
              ))
            )}
          </ul>

          {/* Menú desplegable para seleccionar artículos */}
          {showAvailableArticles && (
            <div className="mt-4">
              <h4 className="text-sm font-semibold">Agregar artículo:</h4>
              <ul className="list-disc list-inside mt-2 text-sm">
                {articulosDisponibles.map((articulo) => (
                  <li key={articulo.id} className="flex justify-between items-center">
                    {articulo.nombreArticulo}
                    <button
                      type="button"
                      className="ml-2 text-green-600 hover:text-green-800"
                      onClick={() => handleAddArticulo(articulo.id)}
                    >
                      Add
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}

      <button
        type="button"
        className="w-full mt-2 py-3 text-white bg-[#0A6A50] rounded-md hover:bg-[#0A6A70]"
        onClick={handleDevolverTodo}
      >
        Devolver todo
      </button>
    </div>
  );
};

export default PrestamoInfo;
