import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from './config'; // Asegúrate de ajustar la ruta según la ubicación de tu archivo config

function Login() {
  const [showPass, setShowPass] = useState(true);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { email, password } = formData;
  
    try {
      // Realizar la solicitud de login
      const response = await axios.post(`${config.baseURL}/api/v1/login`, {
        usuario: email,
        contrasena: password,
      });
  
      const { token } = response.data;
      // Guardar el token en localStorage
      localStorage.setItem("token", token);
  
      // Definición de fetchCuenta
      const fetchCuenta = async () => {
        try {
          const token = localStorage.getItem("token");
          if (!token) throw new Error("Token not found");
      
          const response = await axios.get(`${config.baseURL}/api/v1/cuenta`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
      
          const cuentas = response.data;
          const cuentaUsuario = cuentas.find((cuenta) => cuenta.usuario === email);
      
          if (cuentaUsuario) {
            localStorage.setItem("cuenta", JSON.stringify(cuentaUsuario));
            // Redirigir al usuario a la página principal si la cuenta es válida
            navigate("/menu");
          } else {
            // Limpiar el token en caso de que la cuenta no se encuentre
            localStorage.removeItem("token");
            setError("Cuenta no encontrada para el usuario.");
          }
        } catch (error) {
          console.error("Error fetching cuenta:", error);
          setError("Error al obtener la cuenta.");
        }
      };
      
  
      // Llamar a fetchCuenta solo si el login es exitoso
      await fetchCuenta();
  
    } catch (error) {
      console.error("Error de autenticación", error);
      setError("Usuario o contraseña incorrectos");
      // Limpiar el token en caso de error de autenticación
      localStorage.removeItem("token");
    }
  };
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="flex flex-col h-screen bg-gradient-to-b from-[#0A6A50] to-[#ADE498]">
      <div
        className="grid place-items-center mx-2 my-20 sm:my-auto"
        x-data="{ showPass: true }"
      >
        <div
          className="w-11/12 p-12 sm:w-8/12 md:w-6/12 lg:w-5/12 2xl:w-4/12
                    px-6 py-10 sm:px-10 sm:py-6
                    bg-white rounded-lg shadow-md lg:shadow-lg"
        >
          <div className="text-center mb-4">
            <h1 className="font-semibold text-[#0A6A50] text-3xl mb-2">Sistema de Medios</h1>
            <h6 className="font-semibold text-[#0A6A50] text-xl">
              Iniciar Sesión
            </h6>
          </div>
          <form onSubmit={handleSubmit} className="space-y-5">
            <div>
              <input
                id="email"
                name="email"
                placeholder="Email"
                className="block w-full py-3 px-3 mt-2
                                    text-gray-800 appearance-none
                                    border-2 border-gray-100
                                    focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div className="relative w-full">
              <input
                type={showPass ? "password" : "text"}
                id="password"
                name="password"
                placeholder="Contraseña"
                className="block w-full py-3 px-3 mt-2 mb-4
                                    text-gray-800 appearance-none
                                    border-2 border-gray-100
                                    focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                value={formData.password}
                onChange={handleChange}
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                <p
                  className="font-semibold cursor-pointer"
                  onClick={() => setShowPass(!showPass)}
                >
                  {showPass ? "Mostrar" : "Ocultar"}
                </p>
              </div>
            </div>

            {error && <p className="text-red-500">{error}</p>}

            <button
              type="submit"
              className="w-full py-3 mt-10 bg-[#0A6A50] rounded-md
                                font-medium text-white uppercase
                                focus:outline-none hover:shadow-none"
            >
              Ingresar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
