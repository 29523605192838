import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Header from './Header';
import config from './config'; // Asegúrate de ajustar la ruta según la ubicación de tu archivo config
import ChabotInstructions from './Instructions';
function Colmaia() {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);

  const handleSend = async (e) => {
    e.preventDefault();
    const newMessage = { role: 'user', content: message };
    setMessages((prevMessages) => [...prevMessages, newMessage]);

    try {
      const response = await axios.post(`${config.baseURL}/api/chat/db`, { message },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const botMessage = { role: 'bot', content: response.data.response };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error('Error al enviar la solicitud:', error);
      const errorMessage = { role: 'bot', content: 'Hubo un error al procesar tu solicitud.' };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    }

    setMessage('');
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-[#0A6A50] to-[#ADE498]">
          <Header />
          <div className="flex flex-col-2 flex-grow mx-2 my-20 sm:my-auto">
      <div>
          <ChabotInstructions />
        </div>
        <div className="mt-4 mb-8 flex flex-col flex-grow w-1/2 bg-white rounded-lg shadow-md p-4 overflow-hidden">
        <h1 className="text-[#0A6A50] mb-2 text-3xl font-semibold text-center">Colma-IA</h1>
          <div className="flex flex-col flex-grow h-0 p-4 overflow-auto">
            {messages.map((msg, index) => (
              <div key={index} className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'} mb-2`}>
                <div className={`p-2 rounded-lg ${msg.role === 'user' ? 'bg-[#0A6A50]  text-white' : 'bg-gray-200 text-gray-800'}`}>
                  {msg.content}
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <form onSubmit={handleSend} className="pt-4">
            <div className="flex">
              <input
                type="text"
                id="message"
                name="message"
                placeholder="Ingresa tu consulta"
                className="flex-grow py-2 px-3 mr-2 text-gray-800 border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
              <button
                type="submit"
                className="py-2 px-4 text-white bg-[#0A6A50] rounded-md hover:bg-[#0A6A70]"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      

      </div>
    </div>
  );
}

export default Colmaia;
