import React, { useRef, useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import axios from 'axios';
import config from '../config';
import { format, parseISO, isWithinInterval } from "date-fns";


const InformeArticulosDependencia = () => {


  const printRef = useRef();
  const [prestamoshistorico, setPrestamoshistorico] = useState([]);
  const [analisisTable, setAnalisisTable] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
    const [fechaInicio, setFechaInicio] = useState("");
    const [fechaFin, setFechaFin] = useState("");
  

 // Generar análisis de datos
const generateAnalysisTable = (data) => {
  const facultadesMap = {};

  data.forEach((prestamo) => {
    // Obtener la facultad de manera segura
    const docente = prestamo.Docente || {};
    const personal = prestamo.Personal || {};
    const facultad = docente.facultad || personal.facultad;

    // Verificar si se pudo obtener la facultad
    if (!facultad) return;

    const nombreAula = prestamo.Aula?.nombreAula || "Aula Desconocida";
    const articulos = prestamo.Articulos 
      ? prestamo.Articulos.split(",").map((art) => art.trim()) 
      : [];

    if (!facultadesMap[facultad]) {
      facultadesMap[facultad] = {};
    }

    if (!facultadesMap[facultad][nombreAula]) {
      facultadesMap[facultad][nombreAula] = {
        vecesPrestado: 0,
        articulos: {
          "Portátiles": 0,
          "HDMI": 0,
          "Lápiz Óptico": 0,
        },
      };
    }

    facultadesMap[facultad][nombreAula].vecesPrestado += 1;

    // Contabilizar artículos
    articulos.forEach((articulo) => {
      // Verificar si es un portátil
      if (["P39645", "P39641", "P39628", "P39650", "P39654"].includes(articulo)) {
        facultadesMap[facultad][nombreAula].articulos["Portátiles"] += 1;
      }
      // Verificar si es un HDMI
      else if (/^HDMI \d+$/.test(articulo)) {
        facultadesMap[facultad][nombreAula].articulos["HDMI"] += 1;
      }
      // Verificar si es un Lápiz Óptico
      else if (/^Lápiz Óptico \d+$/.test(articulo)) {
        facultadesMap[facultad][nombreAula].articulos["Lápiz Óptico"] += 1;
      }
    });
  });

  return facultadesMap;
};

  // Fetch de datos históricos
  const fetchPrestamoshistorico = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No hay un token de autenticación");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`${config.baseURL}/api/v1/prestamohistorico`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setPrestamoshistorico(response.data);
    } catch (error) {
      setError("Error obteniendo préstamos históricos");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPrestamoshistorico();
  }, []);

  useEffect(() => {
    if (prestamoshistorico.length > 0) {
      const analysis = generateAnalysisTable(prestamoshistorico);
      setAnalisisTable(analysis);
    }
  }, [prestamoshistorico]);


// Renderizar tabla en el PDF con estilo moderno
const renderTable = (pdf, facultadesMap, marginX, startY) => {
  let yPosition = startY;
  const marginRight = 18;
  const pageWidth = pdf.internal.pageSize.getWidth();
  const maxTableWidth = pageWidth - marginX - marginRight;

  // Tamaño de fuente reducido para la tabla
  const fontSizeTable = 10;

  // Función para verificar si es necesario un salto de página
  const checkPageOverflow = () => {
    if (yPosition > 270) {
      pdf.addPage();
      yPosition = 20;
      yPosition += 20;
    }
  };

 // Estilo de encabezado de la tabla
const drawTableHeader = (header, colWidths) => {
  pdf.setTextColor(0, 0, 0); // Texto negro
  pdf.setFontSize(12); // Mantener un tamaño de fuente más grande para el encabezado

  header.forEach((col, index) => {
      const xPosition = marginX + colWidths.slice(0, index).reduce((a, b) => a + b, 0);
      pdf.text(col, xPosition + 2, yPosition + 3); // Ajuste de posición vertical del texto
      

  });
  yPosition += 12; // Espacio para el encabezado
};


  // Estilo de fila de datos
  const drawTableRow = (row, colWidths, isOdd) => {
    pdf.setTextColor(0, 0, 0); // Texto negro

    // Color de fondo alternante para filas impares
    if (isOdd) {
      pdf.setFillColor(240, 240, 240); // Gris claro
      pdf.rect(
        marginX,
        yPosition - 6,
        colWidths.reduce((a, b) => a + b, 0),
        12,
        "F"
      );
    }

    // Reducir el tamaño de letra en las filas
    pdf.setFontSize(fontSizeTable);

    row.forEach((col, index) => {
      const xPosition = marginX + colWidths.slice(0, index).reduce((a, b) => a + b, 0);
      pdf.text(col, xPosition + 2, yPosition + 3); // Ajuste de posición vertical del texto
    });
    yPosition += 14;
  };

  // Generar la tabla
  Object.keys(facultadesMap).forEach((facultad) => {
    checkPageOverflow();
    pdf.setFontSize(15);
    pdf.setTextColor(0, 0, 0);
    pdf.text(`Facultad: ${facultad}`, marginX, yPosition);
    yPosition += 10;

    const header = ["Salón", "Veces Prestado", "Portátiles", "HDMI", "Lápiz Óptico"];
    
    // Calcular el ancho de cada columna proporcionalmente
    const colWidth = Math.floor(maxTableWidth / header.length);
    const colWidths = Array(header.length).fill(colWidth);

    // Dibujar el encabezado de la tabla
    drawTableHeader(header, colWidths);

    // Obtener aulas ordenadas alfabéticamente
    const aulasOrdenadas = Object.keys(facultadesMap[facultad]).sort();

    // Dibujar filas de datos
    aulasOrdenadas.forEach((aula, index) => {
      checkPageOverflow();
      const { vecesPrestado, articulos } = facultadesMap[facultad][aula];
      const row = [
        aula,
        vecesPrestado.toString(),
        articulos["Portátiles"].toString(),
        articulos["HDMI"].toString(),
        articulos["Lápiz Óptico"].toString(),
      ];

      drawTableRow(row, colWidths, index % 2 !== 0);
    });

    yPosition += 15;
  });
};


// Descargar PDF
const handleDownloadPdf = () => {
  const pdf = new jsPDF("p", "mm", "a4");
  pdf.setFontSize(12);

  // Imagen del logo
  const img = new Image();
  img.src = "/logo.png";

  img.onload = () => {
    // Agregar la imagen al PDF una vez que se haya cargado
    pdf.addImage(img, "PNG", 14, 5, 40, 30);

    // Título y encabezado
    pdf.setFontSize(16);
    pdf.text("Colegio Mayor de Antioquia", 14, 40);
    pdf.setFontSize(14);
    pdf.text("Informe de Préstamos de Salones por Dependencia", 14, 50);

    // Rango de fechas
    const rangoFechas =
      fechaInicio && fechaFin
        ? `Franja de fechas: ${format(parseISO(fechaInicio), "dd/MM/yyyy")} - ${format(parseISO(fechaFin), "dd/MM/yyyy")}`
        : "Historial completo";
    pdf.setFontSize(12);
    pdf.text(rangoFechas, 14, 60);

    // Filtrar los datos antes de generar el análisis
    const prestamosFiltrados = prestamoshistorico.filter((prestamo) => {
      if (!fechaInicio || !fechaFin) return true;

      const fechaPrestamo = parseISO(prestamo.fecha);
      return isWithinInterval(fechaPrestamo, {
        start: parseISO(fechaInicio),
        end: parseISO(fechaFin),
      });
    });

    // Generar la tabla de análisis solo con los datos filtrados
    const analisisTableFiltrado = generateAnalysisTable(prestamosFiltrados);

    // Margen inicial para el contenido
    const marginX = 10;
    let yPosition = 70;

    // Llamar a la función para renderizar la tabla usando los datos filtrados
    renderTable(pdf, analisisTableFiltrado, marginX, yPosition);

    // Guardar el PDF con la fecha actual en el nombre
    const today = format(new Date(), "dd-MM-yyyy");
    pdf.save(`InformeArticulosDependencia_${today}.pdf`);
  };

  // Si la imagen no se carga, mostrar un mensaje de error
  img.onerror = () => {
    alert("Error al cargar el logo. Verifique la ruta de la imagen.");
  };
};



  return (
    <div className="p-8 bg-white shadow rounded">
      <div className="flex justify-between items-center">
        <button onClick={handleDownloadPdf} className="mt-4 py-2 px-4 bg-[#0A6A50] text-white rounded-md shadow hover:bg-[#5A8BA6]">
          Descargar PDF
        </button>

        {/* Seleccionar franjas de fechas */}
        <div className="flex flex-col sm:flex-row gap-4 mt-4">
          <input
            type="date"
            value={fechaInicio}
            onChange={(e) => setFechaInicio(e.target.value)}
            className="border p-2 rounded-md"
          />
          <input
            type="date"
            value={fechaFin}
            onChange={(e) => setFechaFin(e.target.value)}
            className="border p-2 rounded-md"
          />
          </div>


      </div>
      <div ref={printRef} className="text-lg leading-relaxed mt-4">
        {loading ? (
          <p>Cargando datos...</p>
        ) : error ? (
          <p className="text-green-900">{error}</p>
        ) : (
          <p>Datos cargados correctamente. ¡Descarga el PDF!</p>

        
        )}
      </div>

    {/* Mostrar el contenido de la tabla al usuario */}
<div className="overflow-x-auto mt-4">
  <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
    <thead>
      <tr className="bg-[#0A6A50] text-white">
        <th className="px-4 py-2">Facultad</th>
        <th className="px-4 py-2">Salón</th>
        <th className="px-4 py-2">Veces Prestado</th>
        <th className="px-4 py-2">Portátiles</th>
        <th className="px-4 py-2">HDMI</th>
        <th className="px-4 py-2">Lápiz Óptico</th>
      </tr>
    </thead>
    <tbody>
      {Object.keys(analisisTable).map((facultad) => {
        const aulas = Object.keys(analisisTable[facultad]);

        return aulas.map((aula, index) => {
          const { vecesPrestado, articulos } = analisisTable[facultad][aula];

          return (
            <tr key={`${facultad}-${aula}`} className="border-b hover:bg-gray-100">
              {/* Mostrar la facultad solo en la primera fila del grupo */}
              {index === 0 && (
                <td className="px-4 py-2 font-semibold" rowSpan={aulas.length}>
                  {facultad}
                </td>
              )}
              <td className="px-4 py-2">{aula}</td>
              <td className="px-4 py-2">{vecesPrestado}</td>
              <td className="px-4 py-2">{articulos["Portátiles"]}</td>
              <td className="px-4 py-2">{articulos["HDMI"]}</td>
              <td className="px-4 py-2">{articulos["Lápiz Óptico"]}</td>
            </tr>
          );
        });
      })}
    </tbody>
  </table>
</div>


    </div>
  );
};

export default InformeArticulosDependencia;
