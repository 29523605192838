import React, { useState, useEffect } from "react";
import axios from "axios";
import Prestamos from "./Prestamos";
import Header from "./Header";
import moment from 'moment-timezone';
import config from './config'; // Asegúrate de ajustar la ruta según la ubicación de tu archivo config

import { useNavigate } from "react-router-dom";

function Principal() {
  const navigate = useNavigate();

  const [articulos, setArticulos] = useState([]);
  const [computadoresDisponibles, setComputadoresDisponibles] = useState([]);
  const [lapicesOpticosDisponibles, setLapicesOpticosDisponibles] = useState([]);
  const [hdmisDisponibles, setHdmisDisponibles] = useState([]);

  const [prestamos, setPrestamos] = useState([]);
  const [docentes, setDocentes] = useState([]);
  const [personal, setPersonal] = useState([]);
  const [horarios, setHorarios] = useState([]);
  const [aulas, setAulas] = useState([]);

  const cuenta= localStorage.getItem("cuenta");
  const cuentaObj = JSON.parse(cuenta);
  const nombreCuenta = cuentaObj.nombre;


  const [formData, setFormData] = useState({
    salon: "",
    cedula: "",
    docente: "",
    horainicio: "",
    horafin: "",
    llave: "Llave 1",
    hdmi: "",
    computador: "",
    lapizOptico: "",
    observaciones: "",
  
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const [errorAula, setErrorAula] = useState('');
  const [errorCedula, setErrorCedula] = useState('');
  const [errorHorainicio, setErrorHorainicio] = useState('');
  const [errorHorafin, setErrorHorafin] = useState('');

  const handleBluraula = () => {
    const salonesExistentes = aulas.map(aula => aula.nombreAula.toLowerCase());
    const salonIngresado = formData.salon.toLowerCase();
  
    if (!salonesExistentes.includes(salonIngresado)) {
      setErrorAula('Aula incorrecta');
    } else {
      setErrorAula('');
    }
  };

  const handleBlurCedula = () => {
    const cedulasExistentes = docentes.map(docente => docente.cedula);
    const personalExistentes = personal.map(personal => personal.cedula);
    if (!cedulasExistentes.includes(formData.cedula) && !personalExistentes.includes(formData.cedula)) {
      setErrorCedula('Cédula incorrecta o no registrada');
    } else {
      setErrorCedula('');
    }
  };

  const handleBlurHorainicio = () => {
    const horaInicio = formData.horainicio;
    if (horaInicio < 0 || horaInicio > 24) {
      setErrorHorainicio('Hora de inicio incorrecta');
    } else {
      setErrorHorainicio('');
    }
  };

  const handleBlurHorafin = () => {
    const horaFin = formData.horafin;
    if (horaFin < 0 || horaFin > 24) {
      setErrorHorafin('Hora de fin incorrecta');
    } else {
      setErrorHorafin('');
    }
  };



  const fetchDocente = async () => {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token not found");

    try {
      const response = await axios.get(`${config.baseURL}/api/v1/docente`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      setDocentes(response.data.data);
    } catch (error) {
      console.error("Error fetching docente:", error);
    }
  };

  const fetchPersonal = async () => {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token not found");

    try {
      const response = await axios.get(`${config.baseURL}/api/v1/personal`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setPersonal(response.data.data);
    } catch (error) {
      console.error("Error fetching personal:", error);
    }
  };

  const fetchHorario = async () => {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token not found");
    try {
      const response = await axios.get(`${config.baseURL}/api/v1/horario`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setHorarios(response.data.data);
    } catch (error) {
      console.error("Error fetching horario:", error);
    }
  };

  const fetchAula = async () => {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token not found");
    try {
      const response = await axios.get(`${config.baseURL}/api/v1/aula`,{
          headers: {
            Authorization: `Bearer ${token}`
          }
      }	);
      setAulas(response.data.data);
    } catch (error) {
      console.error("Error fetching aula:", error);
    }
  };

const llaves=["Llave 1","Llave 2",""]

  const fetchArticulo = async () => {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token not found");
    try {
        const response = await axios.get(`${config.baseURL}/api/v1/articulo`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const articulos = response.data.data;
        setArticulos(articulos);

        // Filtra y asigna los artículos disponibles
        const disponibles = articulos.filter((articulo) => articulo.disponible === true);

        setComputadoresDisponibles(
            disponibles.filter((articulo) =>
                ["P39645", "P39628", "P39641", "P39650", "P39654"].includes(articulo.nombreArticulo)
            )
        );
        setLapicesOpticosDisponibles(
            disponibles.filter((articulo) =>
                ["Lápiz Óptico 1", "Lápiz Óptico 2", "Lápiz Óptico 3", "Lápiz Óptico 4"].includes(articulo.nombreArticulo)
            )
        );
        setHdmisDisponibles(
            disponibles.filter((articulo) =>
                ["HDMI 1", "HDMI 2", "HDMI 3", "HDMI 4", "HDMI 5", "HDMI 6", "HDMI 7", "HDMI 8", "HDMI 9","HDMI 10", "HDMI 11", "HDMI 12", "HDMI 13", "HDMI 14", "HDMI 15"].includes(articulo.nombreArticulo)
            )
        );
    } catch (error) {
        console.error("Error fetching articulo:", error);
    }
  };

  const fetchPrestamos = async () => {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token not found");
    try {
      const response = await axios.get(`${config.baseURL}/api/v1/prestamo`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setPrestamos(response.data.data);
      

    } catch (error) {
      console.error("Error fetching prestamos:", error);
    }
  };


  

  useEffect(() => {
      fetchArticulo();
      fetchPrestamos();
      fetchDocente();
      fetchHorario();
      fetchAula();
      fetchPersonal();
  }, []);

// Autocompletar el formulario con la información del docente o personal
  useEffect(() => {
    if (formData.cedula) {
      const docenteEncontrado = docentes.find(docente => docente.cedula === formData.cedula);
      if (docenteEncontrado) {
        setFormData(prevFormData => ({
          ...prevFormData,
          docente: prevFormData.docente || docenteEncontrado.nombre,
        }));
      } else {
        const personalEncontrado = personal.find(personal => personal.cedula === formData.cedula);
        if (personalEncontrado) {
          setFormData(prevFormData => ({
            ...prevFormData,
            docente: prevFormData.docente || personalEncontrado.nombre,
          }));
        } else {
          setFormData(prevFormData => ({
            ...prevFormData,
            docente: prevFormData.docente || "",
          }));
        }
      }
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        docente: prevFormData.docente || "",
      }));
    }
  }, [formData.cedula, docentes, personal]);


  // Autocompletar el formulario con la información del horario actual
  useEffect(() => {
    if (formData.salon && horarios.length > 0 && docentes.length > 0 && aulas.length > 0) {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinutes = now.getMinutes();
      const currentTime = currentHour + (currentMinutes / 60); // Convertir a formato decimal
  
      console.log("currentTime:", currentTime);
      // Configuración de la localización a español (Bogotá)
      const options = { weekday: 'long', timeZone: 'America/Bogota' };
      let currentDay = now.toLocaleDateString('es-ES', options).toLowerCase();
      
      // Reemplazar caracteres acentuados
      currentDay = currentDay.replace('á', 'a')
                             .replace('é', 'e')
                             .replace('í', 'i')
                             .replace('ó', 'o')
                             .replace('ú', 'u');
      // Buscar el ID del salón basado en el nombre
      const aulaEncontrada = aulas.find(aula => aula.nombreAula.toLowerCase() === formData.salon.toLowerCase());
  
      if (aulaEncontrada) {
        const horarioEncontrado = horarios.find(horario => {
          const horaInicioDecimal = parseInt(horario.horaInicio);
          const horaFinDecimal = parseInt(horario.horaFin);
          const horaInicioConAnticipacion = horaInicioDecimal - 0.7; // 42 minutos antes de la hora de inicio
          const horaFinConAnticipacion = horaFinDecimal - 0.7;  // 42 minutos antes de la hora de fin
          
       
          // Verificar si la hora actual está dentro de la franja horaria ajustada
          return (
            horario.aulaId === aulaEncontrada.id &&
            horario.dia.toLowerCase() === currentDay &&
            horario.semestre === "2025-1" &&
            ((horaInicioConAnticipacion <= currentTime && horaFinConAnticipacion >= currentTime) || 
             (horaInicioDecimal <= currentTime && horaFinConAnticipacion >= currentTime))
          );
        });
  
        if (horarioEncontrado) {
          const docenteEncontrado = docentes.find(docente => docente.id === horarioEncontrado.docenteId);
  
          setFormData(prevFormData => ({
            ...prevFormData,
            cedula: prevFormData.cedula || (docenteEncontrado ? docenteEncontrado.cedula : ""),
            docente: prevFormData.docente || (docenteEncontrado ? docenteEncontrado.nombre : ""),
            horainicio: horarioEncontrado.horaInicio,
            horafin: horarioEncontrado.horaFin,
          }));
        } else {
          setFormData(prevFormData => ({
            ...prevFormData,
            cedula: prevFormData.cedula || "",
            docente: prevFormData.docente || "",
            horainicio: "",
            horafin: "",
          }));
        }
      } else {
        setFormData(prevFormData => ({
          ...prevFormData,
          cedula: prevFormData.cedula || "",
          docente: prevFormData.docente || "",
          horainicio: "",
          horafin: "",
        }));
      }
    }
  }, [formData.salon, horarios, docentes, aulas]);
  
  
  // Manejar el envío del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token not found");
    const { salon, docente, horainicio, horafin, hdmi, computador, lapizOptico, llave, observaciones } = formData;
    const articulosSeleccionados = [];
    if (hdmi) articulosSeleccionados.push(hdmi);
    if (computador) articulosSeleccionados.push(computador);
    if (lapizOptico) articulosSeleccionados.push(lapizOptico);
  
    try {
      const response = await axios.post(`${config.baseURL}/api/v1/prestamo`, {
        horainicio: horainicio,
        horafin: horafin,
        fecha: moment().tz('America/Bogota').format(),
        devuelto: false,
        Docente: docente, // Enviar el nombre del docente o personal
        Aula: salon,
        Cuenta: nombreCuenta,
        llave: llave,
        observaciones: observaciones,
        articulos: articulosSeleccionados.map(articuloNombre => {
          const articulo = articulos.find(a => a.nombreArticulo === articuloNombre);
          return articulo ? articulo.id : null;
        }).filter(id => id !== null)
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      const prestamohistorico = await axios.post(`${config.baseURL}/api/v1/prestamohistorico`, {
        horainicio: horainicio,
        horafin: horafin,
        fecha: moment().tz('America/Bogota').format(),
        Docente: docente, // Enviar el nombre del docente o personal
        Aula: salon,
        Cuenta: nombreCuenta,
        observaciones: observaciones,
        articulos: articulosSeleccionados
        .filter(articuloNombre => articulos.some(a => a.nombreArticulo === articuloNombre))
        .join(',')

      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      console.log("PrestamoHistorico creado:", prestamohistorico.data);
      console.log("Prestamo creado:", response.data);
  
      if (response.data.success) {
        setFormData({
          salon: "",
          cedula: "",
          docente: "",
          horainicio: "",
          horafin: "",
          llave: "Llave 1",
          hdmi: "",
          computador: "",
          lapizOptico: "",
          observaciones: "",
       
        });
  
        fetchArticulo(); // Llamar a fetchArticulo nuevamente para actualizar la lista de artículos disponibles
        fetchPrestamos(); // Llamar a fetchPrestamos nuevamente para actualizar la lista de préstamos
      }
  
    } catch (error) {
      console.error("Error creating prestamo:", error);
    }
  };

  // Actualizar la lista de préstamos después de devolver un artículo
   const handlePrestamoReturned = (updatedPrestamo) => {
    if (typeof updatedPrestamo === 'number') { 
      setPrestamos((prevPrestamos) => prevPrestamos.filter((prestamo) => prestamo.id !== updatedPrestamo));
    } else { 
      setPrestamos((prevPrestamos) => prevPrestamos.map((prestamo) => prestamo.id === updatedPrestamo.id ? updatedPrestamo : prestamo));
    }
    fetchArticulo();
  };


  // Limpiar el campo de llave si el salón es "Sin aula" o está vacío
  useEffect(() => {
    if (formData.salon.toLowerCase() === "sin aula") {
      setFormData(prevFormData => ({
        ...prevFormData,
        llave: ''
      }));
    }
  }, [formData.salon]);

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-[#0A6A50] to-[#ADE498]">
  <Header />
  <div className="grid place-items-center mx-2">
    <div className="w-full sm:w-2/3 p-6 sm:p-10 mt-4 bg-white rounded-lg shadow-md lg:shadow-lg">
      <div className="text-center mb-4">
        <h1 className="font-semibold text-[#0A6A50] text-3xl">Préstamos Medios</h1>
      </div>

      <form onSubmit={handleSubmit}>
        {/* Grid adaptable para inputs */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-4 text-sm">
          {/* Aula */}
          <div className="flex flex-col">
            <label htmlFor="salon" className="text-sm font-semibold text-gray-600">Aula:</label>
            <input
              type="text"
              id="salon"
              name="salon"
              placeholder="Salón"
              className="py-2 px-3 mt-2 border rounded-md"
              value={formData.salon}
              onChange={handleChange}
              onBlur={handleBluraula}
              required
            />
            {errorAula && <p className="text-red-600 text-sm mt-1">{errorAula}</p>}
          </div>

          {/* Cédula */}
          <div className="flex flex-col">
            <label htmlFor="cedula" className="text-sm font-semibold text-gray-600">Cédula:</label>
            <input
              type="text"
              id="cedula"
              name="cedula"
              placeholder="Cédula"
              className="py-2 px-3 mt-2 border rounded-md"
              value={formData.cedula}
              onChange={handleChange}
              onBlur={handleBlurCedula}
              required
            />
            {errorCedula && <p className="text-red-600 text-sm mt-1">{errorCedula}</p>}
          </div>

          {/* Docente (ocupa dos columnas en pantallas medianas) */}
          <div className="flex flex-col sm:col-span-2">
            <label htmlFor="docente" className="text-sm font-semibold text-gray-600">Docente:</label>
            <input
              type="text"
              id="docente"
              name="docente"
              placeholder="Docente"
              className="py-2 px-3 mt-2 border rounded-md"
              value={formData.docente || ''}
              onChange={handleChange}
            />
          </div>

          {/* Horarios */}
          <div className="flex flex-col">
            <label htmlFor="horainicio" className="text-sm font-semibold text-gray-600">Inicio:</label>
            <input
              type="text"
              id="horainicio"
              name="horainicio"
              placeholder="Hora de inicio"
              className="py-2 px-3 mt-2 border rounded-md"
              value={formData.horainicio || ''}
              onChange={handleChange}
              onBlur={handleBlurHorainicio}
              required
            />
            {errorHorainicio && <p className="text-red-600 text-sm mt-1">{errorHorainicio}</p>}
          </div>

          <div className="flex flex-col">
            <label htmlFor="horafin" className="text-sm font-semibold text-gray-600">Fin:</label>
            <input
              type="text"
              id="horafin"
              placeholder="Hora de fin"
              name="horafin"
              className="py-2 px-3 mt-2 border rounded-md"
              value={formData.horafin || ''}
              onChange={handleChange}
              onBlur={handleBlurHorafin}
              required
            />
            {errorHorafin && <p className="text-red-600 text-sm mt-1">{errorHorafin}</p>}
          </div>
        </div>

        {/* Grid adaptable para selects */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-4 text-sm">
          {[
            { id: "llave", label: "Llave", options: llaves },
            { id: "hdmi", label: "HDMI", options: hdmisDisponibles },
            { id: "computador", label: "Computador", options: computadoresDisponibles },
            { id: "lapizOptico", label: "Lápiz Óptico", options: lapicesOpticosDisponibles }
          ].map(({ id, label, options }) => (
            <div key={id} className="flex items-center">
              <select
                id={id}
                name={id}
                value={formData[id]}
                onChange={handleChange}
                className="block w-full py-2 px-3 border rounded-md"
              >
                <option value="" disabled>{label}</option>
                {options.map((option, index) => (
                  <option key={index} value={option.nombreArticulo || option}>
                    {option.nombreArticulo || option}
                  </option>
                ))}
              </select>
            </div>
          ))}
        </div>

        {/* Observaciones */}
        <div className="flex flex-col text-sm mt-4">
          <input
            type="text"
            id="observaciones"
            name="observaciones"
            placeholder="Observaciones"
            className="py-2 px-3 border rounded-md"
            value={formData.observaciones}
            onChange={handleChange}
          />
        </div>

        {/* Botones en una sola columna en móviles */}
        <div className="mt-4 text-sm grid grid-cols-1 sm:grid-cols-3 gap-2">
          <button type="submit" className="w-full py-3 text-white bg-[#0A6A50] rounded-md hover:bg-[#0A6A70]">
            Guardar
          </button>

          <button type="button" className="w-full py-3 text-white bg-[#0A6A50] rounded-md hover:bg-[#0A6A70]"
            onClick={() => { navigate("/aulas"); }}>
            Ver Aulas
          </button>

          <button type="button" className="w-full py-3 text-white bg-[#0A6A50] rounded-md hover:bg-[#0A6A70]"
            onClick={() => { navigate("/pazysalvo"); }}>
            Paz y Salvo
          </button>
        </div>
      </form>
    </div>
    <Prestamos prestamos={prestamos} onPrestamoReturned={handlePrestamoReturned} />
  </div>
</div>

  );
}

export default Principal;

