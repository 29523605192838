import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "tailwindcss/tailwind.css";
import Login from "./components/Login";
import Principal from "./components/Principal";
import Aula from "./components/Aulas";
import Colmaia from "./components/Colmaia";
import Reportes from "./components/Reportes";
import ProtectedRoute from "./components/ProtectedRoute"; // Asegúrate de importar ProtectedRoute
import Docente from "./components/Docente";
import Bitacora from "./components/Bitacora";
import Pazysalvo from "./components/Pazysalvo";
import Datos from "./components/Datos";
import "./App.css";
import Registros from "./components/Registros";
import Menu from "./components/Menu";
import FormatosInformes from "./components/FormatosInformes";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/menu" element={<ProtectedRoute><Menu /> </ProtectedRoute>} />
          <Route exact path="/principal" element={<ProtectedRoute><Principal /></ProtectedRoute>} />
          <Route exact path="/aulas" element={<ProtectedRoute><Aula /></ProtectedRoute>} />
          <Route exact path="/colmaia" element={<ProtectedRoute><Colmaia /></ProtectedRoute>} />
          <Route exact path="/reportes" element={<ProtectedRoute><Reportes /></ProtectedRoute>} />
          <Route exact path="/docente" element={<ProtectedRoute><Docente /></ProtectedRoute>} />
          <Route exact path="/bitacora" element={<ProtectedRoute><Bitacora /></ProtectedRoute>} />
          <Route exact path="/pazysalvo" element={<ProtectedRoute><Pazysalvo /></ProtectedRoute>} />
          <Route exact path="/datos" element={<ProtectedRoute><Datos /></ProtectedRoute>} />
          <Route exact path="/registros" element={<ProtectedRoute><Registros /></ProtectedRoute>} />
          <Route exact path="/formatosInformes" element={<ProtectedRoute><FormatosInformes /></ProtectedRoute>} />
          {/* Puedes agregar más rutas protegidas aquí */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;