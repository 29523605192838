import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';

const Menu = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-screen bg-gradient-to-b from-[#0A6A50] to-[#ADE498]">
      <Header />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4 mt-6">
          
          {/* Bitácora */}
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-300">
            <h3 className="text-2xl font-semibold text-[#0A6A50]">Bitácora</h3>
            <p className="mt-4  mb-4 text-[#0A6A50]">
              Registro de novedades.<br />
                                    
              Generación de reportes.
            </p>
            <button onClick={() => navigate('/bitacora')} className="block w-full py-3 px-6 text-center rounded-md text-white bg-[#0A6A50] font-bold">
              Acceder
            </button>
          </div>

          {/* Reportes */}
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-300">
            <h3 className="text-2xl font-semibold text-[#0A6A50]">Reportes presencialidad</h3>
            <p className="mt-4 mb-4 text-[#0A6A50]">
              Generación de informes.<br />
              Exportación a formatos PDF<br />
            </p>
            <button onClick={() => navigate('/reportes')} className="block w-full py-3 px-6 text-center rounded-md text-white bg-[#0A6A50] font-bold">
              Acceder
            </button>
          </div>

          {/* Colma-IA */}
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-300">
            <h3 className="text-2xl font-semibold text-[#0A6A50]">Colma-IA</h3>
            <p className="mt-4  mb-4 text-[#0A6A50]">
              Asistente Inteligente basado en IA.<br />
              Respuestas automatizadas.<br />
    
            </p>
            <button onClick={() => navigate('/colmaia')} className="block w-full py-3 px-6 text-center rounded-md text-white bg-[#0A6A50] font-bold">
              Acceder
            </button>
          </div>

          {/* Préstamos */}
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-300">
            <h3 className="text-2xl font-semibold text-[#0A6A50]">Préstamos</h3>
            <p className="mt-4 mb-4 text-[#0A6A50]">
              Gestión de préstamos.<br />
              Control de inventario y devoluciones.<br />
              Generación de informe.
              
            </p>
            <button onClick={() => navigate('/principal')} className="block w-full py-3 px-6 text-center rounded-md text-white bg-[#0A6A50] font-bold">
              Acceder
            </button>
          </div>
        </div>

        {/* Segunda Fila */}
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4 mt-5">
          {/* Histórico */}
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-300">
            <h3 className="text-2xl font-semibold text-[#0A6A50]">Histórico Inventario</h3>
            <p className="mt-4  mb-4 text-[#0A6A50]">
              Datos y registros históricos.<br />
              Búsqueda por fecha.<br />
              Generación de informe.
            </p>
            <button onClick={() => navigate('/datos')} className="block w-full py-3 px-6 text-center rounded-md text-white bg-[#0A6A50] font-bold">
              Acceder
            </button>
          </div>

          {/* Agregar Usuario */}
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-300">
            <h3 className="text-2xl font-semibold text-[#0A6A50]">Agregar Usuario</h3>
            <p className="mt-4 mb-4 text-[#0A6A50]">
              Creación de docentes.<br />
              Creación personal.<br />
            </p>
            <button onClick={() => navigate('/docente')} className="block w-full py-3 px-6 text-center rounded-md text-white bg-[#0A6A50] font-bold">
              Acceder
            </button>
          </div>

          {/* Registros */}
          <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-300">
            <h3 className="text-2xl font-semibold text-[#0A6A50]">Registros</h3>
            <p className="mt-4  mb-4 text-[#0A6A50]">
              Registros de decentes<br />
              Registros de personal<br />
             
            </p>
            <button onClick={() => navigate('/registros')} className="block w-full py-3 px-6 text-center rounded-md text-white bg-[#0A6A50] font-bold">
              Acceder
            </button>
        </div>

        {/* Formatos Informes */}
        <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition duration-300">
          <h3 className="text-2xl font-semibold text-[#0A6A50]">Formatos e Informes</h3>
          <p className="mt-4 mb-4 text-[#0A6A50]">
            Generación de formatos e informes.<br />
            Exportación a formatos PDF<br />
          </p>
          <button onClick={() => navigate('/formatosinformes')} className="block w-full py-3 px-6 text-center rounded-md text-white bg-[#0A6A50] font-bold">
            Acceder
          </button>
          </div>

    

      </div>
      </div>
    </div>

  );
};

export default Menu;
