import React, { useState, useEffect } from "react";
import PrestamoInfo from "./PrestamoInfo";
import Informe from "./Informe";

const Prestamos = ({ prestamos, onPrestamoReturned }) => {
  const [busqueda, setBusqueda] = useState('');
  const [resultadosFiltrados, setResultadosFiltrados] = useState(prestamos);
  const [orden, setOrden] = useState('fecha-desc');
  const cantprestamos=prestamos.length;

  useEffect(() => {
    const lowercasedFilter = busqueda.toLowerCase();
    const filteredData = prestamos.filter((prestamo) => {
      const prestamoData = JSON.stringify(prestamo).toLowerCase();
      return prestamoData.includes(lowercasedFilter);
    });
    setResultadosFiltrados(filteredData);
  }, [busqueda, prestamos]);

  const handleBusquedaChange = (event) => {
    setBusqueda(event.target.value);
  };

  const handleOrdenChange = (event) => {
    setOrden(event.target.value);
  };

  const ordenarPrestamos = (prestamos) => {
    let prestamosOrdenados = [...prestamos];
    switch (orden) {
      case 'fecha-asc':
        prestamosOrdenados.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
        break;
      case 'fecha-desc':
        prestamosOrdenados.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
        break;
      case 'objetos':
        prestamosOrdenados.sort((a, b) => (b.ArticuloPrestamo.length > 0 ? 1 : -1) - (a.ArticuloPrestamo.length > 0 ? 1 : -1));
        break;
      default:
        break;
    }
    return prestamosOrdenados;
  };

  const prestamosOrdenados = ordenarPrestamos(resultadosFiltrados);

  return (
    <div className="flex flex-col items-center w-full text-sm">
    <div className="w-full sm:w-2/3 p-4 sm:p-6 bg-white rounded-lg shadow-md lg:shadow-lg mt-2">
      <div className="flex flex-col mb-4">
        <label htmlFor="buscarSalon" className="text-sm font-semibold text-gray-600">
          Buscar Salón:
        </label>
        <input
          type="text"
          id="busqueda"
          name="busqueda"
          placeholder="Buscar préstamo"
          className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
          value={busqueda}
          onChange={handleBusquedaChange}
        />
      </div>
      <div className="flex flex-col mb-4">
        <label htmlFor="ordenarPor" className="text-sm font-semibold text-gray-600">
          Ordenar por:
        </label>
        <select
          id="ordenarPor"
          className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
          value={orden}
          onChange={handleOrdenChange}
        >
          <option value="fecha-desc">Fecha más reciente</option>
          <option value="fecha-asc">Fecha más antiguo</option>
          <option value="objetos">Objetos (si tiene artículos)</option>
        </select>
      </div>
      <p className="text-sm font-semibold text-gray-600">Cantidad Prestamos: {cantprestamos}</p>

      <Informe />
    </div>
    
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 w-full  mt-2">
      {prestamosOrdenados.map((prestamo, index) => (
        <div key={index}>
          <PrestamoInfo formData={prestamo} onPrestamoReturned={onPrestamoReturned} />
        </div>
      ))}
    </div>
  </div>
  );
};

export default Prestamos;
