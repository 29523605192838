import React, { useState, useEffect, useMemo } from "react";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import InformeBitacora from "./InformeBitcora";

const BitacorasInfo = ({ formData }) => {
  const {
    
    cedula,
    nombre,
    observacion,
    fecha,
    estado,
    Docente,
    Personal,
    fotoUrl,
  } = formData;


  const [mostrarModal, setMostrarModal] = useState(false);

  const formatDate = (dateString) => {
    if (!dateString) return "Fecha no especificada";
    const date = new Date(dateString);
    return format(date, "EEEE d 'de' MMMM 'del' yyyy HH:mm", { locale: es });
  };

  // Convertir la imagen base64 a un objeto de imagen, viene de la base de datos la cadena base64
  // Convertir la imagen base64 a una URL válida
  const imagenSrc = useMemo(() => (fotoUrl ? `data:image/jpeg;base64,${fotoUrl}` : null), [fotoUrl]);


 

  return (
    <div className="p-4 sm:p-6 bg-white rounded-lg shadow-md mt-2 mx-2 mb-2">
      <h2 className="text-lg text-[#0A6A50] font-bold">Registro de Bitácora:</h2>
      <div className="grid grid-cols-1 mt-2 text-sm">
       
        <p><span className="font-semibold">Cédula:</span> {cedula || "Cédula no especificada"}</p>
        <p><span className="font-semibold">Nombre:</span> {nombre || "Nombre no especificado"}</p>
        <p><span className="font-semibold">Fecha:</span> {formatDate(fecha)}</p>
        <p><span className="font-semibold">Estado:</span> {estado || "Estado no especificado"}</p>
        <p><span className="font-semibold">Observaciones:</span> {observacion || "No especificadas"}</p>
        {Docente && <p><span className="font-semibold">Asignado a Docente:</span> {Docente.nombre} ({Docente.cedula})</p>}
        {Personal && <p><span className="font-semibold">Asignado a Personal:</span> {Personal.nombre} ({Personal.cedula})</p>}

        {imagenSrc && (
          <div className="mt-4">
            <button
              onClick={() => setMostrarModal(true)}
              className="text-[#0A6A50] focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-4 py-2 mt-2 bg-gray-100 hover:bg-gray-200"
            >
              Ver Imagen
            </button>
          </div>
        )}
      </div>

      {/* MODAL PARA MOSTRAR LA IMAGEN */}
      {mostrarModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl p-4 sm:p-6 relative">
            {/* Botón para cerrar */}
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold text-[#0A6A50]">Imagen de Bitácora</h3>
              <button
                onClick={() => setMostrarModal(false)}
                className="text-[#0A6A50] font-bold text-lg"
              >
                ✕
              </button>
            </div>

            {/* Contenedor de la imagen */}
            <div className="flex justify-center">
              <img src={imagenSrc} alt="Imagen de Bitácora" className="rounded-md shadow-md max-h-[70vh]" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const BitacoraRegistros = ({ bitacoras }) => {
  const [busqueda, setBusqueda] = useState("");
  const [resultadosFiltrados, setResultadosFiltrados] = useState([]);
  const [orden, setOrden] = useState("fecha-desc");

  // Mostrar solo los primeros 30 registros de la bitacora para optimizar la carga




  useEffect(() => {
    // Lógica de filtrado optimizada
    const filteredData = bitacoras.filter((bitacora) => {
      const { id, cedula, nombre, observacion, estado } = bitacora;
      const filterText = busqueda.toLowerCase();
      return (
        (id && id.toString().toLowerCase().includes(filterText)) ||
        (cedula && cedula.toLowerCase().includes(filterText)) ||
        (nombre && nombre.toLowerCase().includes(filterText)) ||
        (observacion && observacion.toLowerCase().includes(filterText)) ||
        (estado && estado.toLowerCase().includes(filterText))
      );
    });
    setResultadosFiltrados(filteredData);
  }, [busqueda, bitacoras]);

  const ordenarBitacoras = (bitacoras) => {
    const bitacorasOrdenadas = [...bitacoras];
    bitacorasOrdenadas.sort((a, b) => {
      const fechaA = new Date(a.fecha);
      const fechaB = new Date(b.fecha);
      return orden === "fecha-asc" ? fechaA - fechaB : fechaB - fechaA;
    });
    return bitacorasOrdenadas;
  };

  const handleBusquedaChange = (event) => {
    setBusqueda(event.target.value);
  };

  const handleOrdenChange = (event) => {
    setOrden(event.target.value);
  };

  const bitacorasOrdenadas = ordenarBitacoras(resultadosFiltrados);

  return (
    <div className="flex flex-col items-center">
    <div className="w-full sm:w-1/2 p-4 sm:p-6 bg-white rounded-lg shadow-md lg:shadow-lg">
      <div className="flex flex-col">
        <label htmlFor="buscarBitacora" className="text-sm font-semibold text-gray-600">
          Buscar Bitácora:
        </label>
        <input
          type="text"
          id="busqueda"
          name="busqueda"
          placeholder="Buscar Bitácora"
          className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
          value={busqueda}
          onChange={handleBusquedaChange}
        />
      </div>

      <div className="flex flex-col mb-4 mt-4">
        <label htmlFor="ordenarPor" className="text-sm font-semibold text-gray-600">
          Ordenar por:
        </label>
        <select
          id="ordenarPor"
          className="py-2 px-3 mt-2 text-gray-800 appearance-none border-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200 rounded-md"
          value={orden}
          onChange={handleOrdenChange}
        >
          <option value="fecha-desc">Fecha más reciente</option>
          <option value="fecha-asc">Fecha más antiguo</option>
        </select>

        <InformeBitacora />
      </div>
    </div>

    {/* Mostrar los registros de la bitácora solo los ultimos 30 registros*/}
    
    <h1 className="text-[#0A6A50] text-3xl font-semibold text-center mt-4">Registros de Bitácora</h1>

    
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 w-full  mt-2">
      {bitacorasOrdenadas.map((bitacora, index) => (
        <BitacorasInfo key={index} formData={bitacora} />
      ))}
    </div>
    

  </div>
  );
};

export default BitacoraRegistros;
