import React, { useState } from "react";
import Header from "./Header";
import InformeArticulosDependencia from "./INFORMES/InformeArticulosDependencia";

const InformePrestamos = () => {

    const abrirModal = () => {
        setMostrarModal(true);
      };
      
      const cerrarModal = () => {
        setMostrarModal(false);
      };
      const [mostrarModal, setMostrarModal] = useState(false);
      

    return (
        <div className="flex flex-col min-h-screen bg-gradient-to-b from-[#0A6A50] to-[#ADE498]">
        <Header />
            <div className=" mt-4 w-1/2 container mx-auto bg-white p-6 rounded-lg shadow-md">
              <h2 className="text-3xl font-semibold text-[#0A6A50] text-center">Generación de formatos e Informes</h2>
       {/* Boton de generacion de formato para ingresar excel o cvs */}
      
              <div className="flex justify-center mt-4">
                  <input
                      type="file"
                      accept=".xlsx, .xls, .csv"
                      placeholder="Ingresar archivo de estudiantes de Naonsoft"
                      className="border border-gray-300 rounded-lg p-2 w-1/2"
                  />
      
      <button className="bg-[#0A6A50] text-white px-4 py-2 rounded-lg hover:bg-[#ADE498] transition duration-300">
                  Generar Formato de correos estudiantiles
                </button>
              </div>
      
            <div className="flex justify-center mt-4">
              <button
            type="button"
            onClick={abrirModal}
            className=" bg-[#0A6A50] hover:bg-[#0A6A50] text-white font-semibold py-2 px-4 rounded-md mt-4 transition duration-300"
          >
            Ver Informe Prestamos de articulos por dependencia
          </button>
            </div>
           {/* Modal para mostrar el informe de Registro y Lectura */}
           {mostrarModal && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div
                className="bg-white rounded-lg shadow-lg w-full max-w-5xl max-h-full overflow-y-auto p-6 relative"
                style={{ height: "90%", display: "flex", flexDirection: "column" }}
              >
                <div className="flex justify-between items-center mb-4">
               
                  <button
                    onClick={cerrarModal}
                    className="text-[#0A6A50] font-bold text-lg"
                  >
                    ✕
                  </button>
                </div>
          
                {/* Contenedor flexible para que InformeCotizacion se ajuste al espacio */}
                <div className="flex-grow overflow-y-auto">
             <InformeArticulosDependencia />
                </div>
              </div>
            </div>
          )}
         
    
                {/* Boton de generacion de informe de prestamos por dependencia */} 
              <div className="flex justify-center mt-4">
                  <input
                      type="file"
                      accept=".xlsx, .xls, .csv"
                      placeholder="Ingresar archivo de estudiantes de Naonsoft"
                      className="border border-gray-300 rounded-lg p-2 w-1/2"
                  />
      <button className="bg-[#0A6A50] text-white px-4 py-2 rounded-lg hover:bg-[#ADE498] transition duration-300">
                  Importar horarios de clases
                </button>
              </div>
      
            </div>
          </div>
    );
};

export default InformePrestamos;
