import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "./config";
import Header from "./Header";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { format, parseISO, isWithinInterval } from "date-fns";

const Datos = () => {
  const [prestamos, setPrestamos] = useState([]);
  const [conteoArticulos, setConteoArticulos] = useState({});
  const [conteoAulas, setConteoAulas] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");

  useEffect(() => {
    obtenerPrestamosHistoricos();
  }, []);

  useEffect(() => {
    // Cada vez que cambian las fechas, recalcular conteos
    actualizarConteos();
  }, [prestamos, fechaInicio, fechaFin]);

  const obtenerPrestamosHistoricos = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("No hay un token de autenticación");
      setLoading(false);
      return;
    }
    try {
      const response = await axios.get(`${config.baseURL}/api/v1/prestamohistorico`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      setPrestamos(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error obteniendo préstamos históricos: ", error);
      setError("Error obteniendo préstamos históricos");
      setLoading(false);
    }
  };

  const prestamosFiltrados = prestamos.filter((prestamo) => {
    if (!fechaInicio || !fechaFin) return true;

    const fechaPrestamo = parseISO(prestamo.fecha);
    return isWithinInterval(fechaPrestamo, {
      start: parseISO(fechaInicio),
      end: parseISO(fechaFin),
    });
  });

  const actualizarConteos = () => {
    const conteoArticulos = {};
    const conteoAulas = {};

    prestamosFiltrados.forEach((prestamo) => {
      if (prestamo.Articulos) {
        const articulos = prestamo.Articulos.split(",").map((art) => art.trim());
        articulos.forEach((articulo) => {
          conteoArticulos[articulo] = (conteoArticulos[articulo] || 0) + 1;
        });
      }

      if (prestamo.Aula && prestamo.Aula.nombreAula) {
        const nombreAula = prestamo.Aula.nombreAula;
        conteoAulas[nombreAula] = (conteoAulas[nombreAula] || 0) + 1;
      }
    });

    setConteoArticulos(conteoArticulos);
    setConteoAulas(conteoAulas);
  };

  const generarPDF = () => {
    const pdf = new jsPDF();
    pdf.setFontSize(12);

    // Imagen del logo
    const img = new Image();
    img.src = "/logo.png";

    img.onload = () => {
      pdf.addImage(img, "PNG", 14, 5, 40, 30);

      pdf.text("Colegio Mayor de Antioquia", 14, 40);
      pdf.text("Historial de Préstamos", 14, 50);

      const rangoFechas =
        fechaInicio && fechaFin
          ? `Franja de fechas: ${format(parseISO(fechaInicio), "dd/MM/yyyy")} - ${format(parseISO(fechaFin), "dd/MM/yyyy")}`
          : "Historial completo";

      pdf.text(rangoFechas, 14, 60);
      let y = 70;

      // **Tabla de Artículos Filtrados**
      autoTable(pdf, {
        startY: y,
        head: [["Artículo", "Veces Prestado"]],
        body: Object.entries(conteoArticulos).map(([articulo, cantidad]) => [articulo, cantidad]),
        styles: { fontSize: 8 },
        headStyles: { fillColor: [10, 106, 80], textColor: [255, 255, 255] },
        margin: { left: 14, right: 14 },
      });

      y = pdf.lastAutoTable.finalY + 10;

      // **Tabla de Aulas Filtradas**
      autoTable(pdf, {
        startY: y,
        head: [["Aula", "Veces Prestada"]],
        body: Object.entries(conteoAulas).map(([aula, cantidad]) => [aula, cantidad]),
        styles: { fontSize: 8 },
        headStyles: { fillColor: [10, 106, 80], textColor: [255, 255, 255] },
        margin: { left: 14, right: 14 },
      });

      const today = format(new Date(), "dd-MM-yyyy");
      pdf.save(`HistorialPrestamos-${today}.pdf`);
    };
  };

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-[#0A6A50] to-[#ADE498]">
      <Header />
      <div className="grid place-items-center mx-2">
        <div className="w-full sm:w-2/3 p-6 sm:p-10 mt-4 bg-white rounded-lg shadow-md lg:shadow-lg">
          <h1 className="text-center font-semibold text-[#0A6A50] text-3xl">Historial de Préstamos</h1>

          {/* Filtros de fecha */}
          <div className="flex flex-col sm:flex-row justify-center gap-4 my-4">
            <input
              type="date"
              value={fechaInicio}
              onChange={(e) => setFechaInicio(e.target.value)}
              className="border p-2 rounded-md"
            />
            <input
              type="date"
              value={fechaFin}
              onChange={(e) => setFechaFin(e.target.value)}
              className="border p-2 rounded-md"
            />
          </div>

          <button
            onClick={generarPDF}
            className="px-4 py-2 bg-[#0A6A50] text-white rounded-md hover:bg-[#085C42] w-full sm:w-auto"
          >
            Descargar PDF
          </button>

          {/* Tablas */}
          <div className="overflow-x-auto mt-6">
            <h3 className="text-lg font-semibold text-[#0A6A50]">Artículos Prestados</h3>
            <table className="min-w-full border border-gray-300 bg-white rounded-lg shadow-sm">
              <thead className="bg-[#0A6A50] text-white">
                <tr>
                  <th className="py-2 px-4 border-b text-left">Artículo</th>
                  <th className="py-2 px-4 border-b text-left">Veces Prestado</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(conteoArticulos).map(([articulo, cantidad]) => (
                  <tr key={articulo} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b">{articulo}</td>
                    <td className="py-2 px-4 border-b">{cantidad}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <h3 className="text-lg font-semibold text-[#0A6A50] mt-6">Aulas Prestadas</h3>
            <table className="min-w-full border border-gray-300 bg-white rounded-lg shadow-sm">
              <thead className="bg-[#0A6A50] text-white">
                <tr>
                  <th className="py-2 px-4 border-b text-left">Aula</th>
                  <th className="py-2 px-4 border-b text-left">Veces Prestada</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(conteoAulas).map(([aula, cantidad]) => (
                  <tr key={aula} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b">{aula}</td>
                    <td className="py-2 px-4 border-b">{cantidad}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Datos;
