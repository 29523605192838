import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  // Función para cerrar sesión
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("cuenta");
    navigate("/");
  };

  // Obtener la información de la cuenta desde localStorage y parsearla
  const cuentaStr = localStorage.getItem("cuenta");
  let nombreCuenta = "Usuario";
  if (cuentaStr) {
    const cuentaObj = JSON.parse(cuentaStr);
    nombreCuenta = cuentaObj.nombre;
  }

  // Verificar si el token es válido (puedes agregar lógica para decodificar JWT si lo necesitas)
  const verificarToken = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      handleLogout(); // Si no hay token, cerrar sesión automáticamente
    }
  };

  useEffect(() => {
    verificarToken(); // Verificar token al montar el componente

    // Configurar un intervalo para chequear el token cada 5 minutos
    const intervalo = setInterval(verificarToken, 5 * 60 * 1000);

    return () => clearInterval(intervalo); // Limpiar intervalo cuando el componente se desmonte
  }, []);

  return (
    <header>
      <nav className="border-gray-200 lg:px-6 py-2.5 bg-white">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <div className="flex items-center lg:order-1">
            <img src="colmayor.png" className="ml-3 mr-3 h-6 sm:h-9" alt="Colegio Mayor Logo" />
            <span className="self-center text-xl font-semibold whitespace-nowrap text-[#0A6A50]">
              Colegio Mayor de Antioquia
            </span>
            <span className="text-[#0A6A50] text-sm font-semibold ml-4">{nombreCuenta}</span>
          </div>

          <div className="flex items-center lg:order-2">
            <button
              onClick={() => navigate("/menu")}
              className="text-[#0A6A50] focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 hover:bg-gray-100 focus:outline-none"
            >
              Menú
            </button>

            <button
              onClick={handleLogout}
              className="text-[#0A6A50] focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 hover:bg-gray-100 focus:outline-none"
            >
              Cerrar Sesión
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
